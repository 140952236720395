import { FunctionComponent } from 'react';
import Spinner from '../../public/icons/spinner.svg';

interface SpinnerProps {
  width: string | number;
  height: string | number;
  [x: string]: unknown;
}

const RawSpinner: FunctionComponent<SpinnerProps> = props => (
  <div className='loading-spinner flex justify-center items-center'>
    <Spinner color='var(--primary-color)' {...props} />
    <style jsx>
      {`
        .loading-spinner {
          border-radius: 50%;
          line-height: 11px;

          animation: spinner 1s;
          animation-iteration-count: infinite;
        }
        @keyframes spinner {
          0% {
            transform: rotate(-70deg);
          }
          100% {
            transform: rotate(290deg);
          }
        }
      `}
    </style>
  </div>
);

export default RawSpinner;
